import React from 'react';
import { PropTypes } from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';

import * as UI from '../../../../ui';

import { Leaders } from './Leaders';

const { DynamicContainer, ContainerType, Title, TitleTypes, Text, TextTypes, FormattedMessageWithLinks } = UI;

const LeadershipSection = ({ styles, reference, rules }) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const formattedLink = formatMessage({ id: 'common.links.linkedIn' });

    return (
        <DynamicContainer as={ContainerType.SECTION} extend={rules.extendContainer} ref={reference} dark>
            <DynamicContainer isFluid ref={reference} className={styles.container}>
                <Title extend={rules.extendTitle} as={TitleTypes.H2} type={TitleTypes.H1_ITALIC_UPPERCASE}>
                    <FormattedMessage id="aboutUsPage.leadership.title" />
                </Title>
                <Text type={TextTypes.LARGE} extend={rules.extendText} as="p">
                    <FormattedMessageWithLinks
                        extend={{ link: rules.textLink }}
                        id="aboutUsPage.leadership.description"
                    />
                </Text>
                <Leaders />

                <a href={formattedLink} className={styles.link} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="aboutUsPage.leadership.linkText" />
                </a>
            </DynamicContainer>
        </DynamicContainer>
    );
};

LeadershipSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    reference: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default LeadershipSection;
