import { theme } from 'gatsby-theme-fela';

import type { MessageKey } from 'modules/intl';

export interface Leader {
    id: number;
    name: MessageKey;
    position: MessageKey;
    img: string;
    shadowColor: (typeof theme.colors)[keyof typeof theme.colors];
}

export const content = [
    {
        id: 1,
        name: 'aboutUsPage.leadership.leaders.1.name',
        position: 'aboutUsPage.leadership.leaders.1.position',
        img: 'about-us/leaders/pulpi.jpg',
        shadowColor: theme.colors.fuchsia,
    },
    {
        id: 2,
        name: 'aboutUsPage.leadership.leaders.2.name',
        position: 'aboutUsPage.leadership.leaders.2.position',
        img: 'about-us/leaders/domca.jpg',
        shadowColor: theme.colors.blue,
    },
    {
        id: 3,
        name: 'aboutUsPage.leadership.leaders.3.name',
        position: 'aboutUsPage.leadership.leaders.3.position',
        img: 'about-us/leaders/pepus.jpg',
        shadowColor: theme.colors.fuchsia,
    },
    {
        id: 4,
        name: 'aboutUsPage.leadership.leaders.4.name',
        position: 'aboutUsPage.leadership.leaders.4.position',
        img: 'about-us/leaders/jm.jpg',
        shadowColor: theme.colors.cyan,
    },
] as const satisfies readonly Leader[];
