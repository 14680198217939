import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';

import { content } from './content';
import { SingleLeader } from './SingleLeader';
import * as felaRules from './Leaders.rules';

export const Leaders = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            {content.map(item => (
                <SingleLeader key={item.id} content={item} />
            ))}
        </div>
    );
};
