import type { TRuleWithTheme } from '@ackee/fela';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '81rem',
    position: 'relative',
    zIndex: 2,
    marginTop: '5rem',
    marginBottom: '4.5rem',

    tablet: {
        flexDirection: 'row',
    },
});
